import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Activity from "./Activity"
import Overview from "./Overview"
import QCComments from "./QCComments"
import { useLoading, useUnreadQCRequestsCount } from "helpers/custom_hooks"
import { GetMyUser, GetQCRequestByID } from "helpers/backend_helper"
import { useNavigate, useParams } from "react-router-dom"
import { IsQCModuleEnabled, updateUnreadQCRequests } from "helpers/misc_helpers"
import QCRatingModal from "./QCRatingModal"

const QCOverview = props => {
  const [entry, setEntry] = useState()
  const [myUser, setMyUser] = useState()
  const [ratingModal, setRatingModal] = useState(true)
  const navigate = useNavigate()
  const [_, setUnreadQCRequestsCount] = useUnreadQCRequestsCount()
  const setLoading = useLoading()
  let { id } = useParams()

  const loadData = async () => {
    setLoading(true)
    try {
      const result = await GetQCRequestByID(id)
      const user = await GetMyUser()
      setMyUser(user)

      setEntry(result)
    } catch (error) {
      console.log(error)
      if (error.response.status === 404) navigate("/404")
      else alert("An unexpected error happened!")
    }
    setLoading(false)

    setTimeout(() => {
      updateUnreadQCRequests(setUnreadQCRequestsCount)
    }, 1000)
  }

  useEffect(() => {
    loadData()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Details" breadcrumbItem="Quality Check" />

          {!isEmpty(entry) && (
            <>
              <Row>
                <Col lg="4">
                  <Overview entry={entry} printBtn />
                </Col>
                <Col lg="4">
                  <Activity entry={entry} myUser={myUser} reload={loadData} />
                </Col>
                <Col lg="4">
                  <QCComments entry={entry} reload={loadData} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>

      <QCRatingModal
        entry={entry}
        show={ratingModal}
        myUser={myUser}
        onCancel={_ => setRatingModal(false)}
      />
    </React.Fragment>
  )
}

QCOverview.propTypes = {
  match: PropTypes.object,
}

export default withRouter(QCOverview)
