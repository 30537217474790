import { GetQCLaborsForTest } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useEffect, useState } from "react"
import { Label, TabPane } from "reactstrap"

const QCLaborTab = ({ nextTab, request, setRequest }) => {
  const [entries, setEntries] = useState([])
  const setLoading = useLoading()

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    const result = await GetQCLaborsForTest(request?.qcTestID)
    setEntries(result)
  }

  return (
    <TabPane tabId={3}>
      <h6 className="mb-4 card-title">QC-Labors:</h6>
      <p>Please select a QC Lab</p>
      <div className="controls mb-4">
        {entries.map((entry, index) => (
          <div className="form-check mb-2" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="qcLabor"
              id={"qc-labor" + entry.id}
              value={entry.id}
              onChange={() => setRequest({ ...request, qcLaborID: entry.id })}
            />
            <Label className="form-check-label" htmlFor={"qc-labor" + entry.id}>
              {entry.name}
            </Label>
          </div>
        ))}

        <div className="d-flex justify-content-end">
          <button
            type="button"
            onClick={_ => nextTab(1)}
            disabled={!request?.qcLaborID}
            className="btn btn-primary"
          >
            Next
          </button>
        </div>
      </div>
    </TabPane>
  )
}

export default QCLaborTab
