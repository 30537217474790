import React from "react"
import QCReportFileDrop from "./QCReportFileDrop"
import moment from "moment"
import { isUserRequestor } from "helpers/misc_helpers"

const QCReportListItem = ({ entry, myUser, statusIndex, reload }) => {
  const IsUserQCLabor = () => myUser?.company?.id == entry?.qcLaborID

  return (
    <>
      {entry?.reportFileURL && entry?.statusIndex >= statusIndex ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <a
            href={entry?.reportFileURL}
            target="_blank"
            rel="noreferrer"
            className="text-dark"
          >
            <div
              className={
                "event-timeline-dot " +
                (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
              }
            >
              <i className="bx bx-right-arrow-circle font-size-18"></i>
            </div>
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <h5 className="font-size-14">
                  {moment(entry?.dateReviewDone).format("DD.MM.YYYY")}{" "}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                </h5>
              </div>
              <div className="flex-grow-1">
                <div>Report provided by QC Lab</div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div style={{ width: "45px" }}>
                <div className="avatar-sm">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                    <i className="bx bxs-file-doc" />
                  </span>
                </div>
              </div>
              <div className=" ms-4">
                <h5 className="font-size-14 mb-1">QC Report</h5>
                <small>Klick To Download</small>
              </div>
              <div className="text-center ms-4">
                <i className="bx bx-download h3 m-0" />
              </div>
            </div>
          </a>

          <div className="mt-4">
            <b>Report Status:</b>
          </div>
          <div className="d-flex mt-2">
            <div className="ms-2">
              {entry?.reportStatus === "acceptable" && (
                <span className="badge bg-success px-2 py-1">Acceptable</span>
              )}
              {entry?.reportStatus === "fail" && (
                <span className="badge bg-danger px-2 py-1">Fail</span>
              )}
              {entry?.reportStatus === "far" && (
                <span className="badge bg-warning px-2 py-1">
                  Further Analysis recommended
                </span>
              )}
            </div>
          </div>

          <div className="mt-3">
            <b>Recommendation:</b>
          </div>
          <div className="d-flex mt-2">
            <div className="ms-2">
              <p>{entry?.recommendation ?? "-"}</p>
            </div>
          </div>
          <div className="mt-2">
            <b>Additional Costs (excl. VAT):</b>
          </div>
          <div className="d-flex mt-2">
            <div className="ms-2">
              <p>
                {entry?.additionalCosts
                  ? entry?.additionalCosts + " " + entry?.currency
                  : "-"}{" "}
              </p>
            </div>
          </div>

          <div className="mt-2">
            <b>Buyer Status:</b>
          </div>
          <div className="d-flex mt-2">
            <div className="ms-2">
              {entry?.dateAccepted && (
                <span className="badge bg-success px-2 py-1">Confirmed</span>
              )}
              {entry?.dateDeclined && (
                <span className="badge bg-danger px-2 py-1">Declined</span>
              )}
              {!entry?.dateAccepted && !entry?.dateDeclined && (
                <span className="badge bg-warning px-2 py-1">Pending</span>
              )}
            </div>
          </div>
          {entry?.dateAccepted && isUserRequestor(myUser, entry) && (
            <div className="mt-1">
              <p>Please wait for the seller to accept the additional tests.</p>
            </div>
          )}
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        IsUserQCLabor() && <QCReportFileDrop entry={entry} reload={reload} />
      )}
    </>
  )
}

export default QCReportListItem
