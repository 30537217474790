import React from "react"
import { Card, CardBody } from "reactstrap"
import SimpleBar from "simplebar-react"
import QCOfferListItem from "./ActivityListItems/QCOffer"
import QCAcceptOfferListItem from "./ActivityListItems/QCAcceptOffer"
import QCTrackingNumberInput from "./ActivityListItems/QCTrackingNumberInput"
import QCRequestCreated from "./ActivityListItems/QCRequestCreated"
import QCReportListItem from "./ActivityListItems/QCReport"
import QCAcceptReportListItem from "./ActivityListItems/QCAcceptReport"
import QCOrderInformationInput from "./ActivityListItems/QCOrderInformationInput"
import QCDeclinedInfo from "./ActivityListItems/QCDeclinedInfo"
import QCGoodsReceived from "./ActivityListItems/QCGoodsReceived"
import QCReleaseGoods from "./ActivityListItems/QCReleaseGoods"
import QCBuyerGoodsReceived from "./ActivityListItems/QCBuyerGoodsReceived"

const Activity = ({ entry, myUser, reload }) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-start">
          <div className="me-2">
            <h5 className="card-title mb-4">Activity</h5>
          </div>
        </div>
        <SimpleBar className="mt-2">
          <ul className="verti-timeline list-unstyled">
            <QCRequestCreated entry={entry} statusIndex={1} />
            <QCOfferListItem
              entry={entry}
              myUser={myUser}
              statusIndex={2}
              reload={reload}
            />
            <QCAcceptOfferListItem
              entry={entry}
              myUser={myUser}
              statusIndex={4}
              reload={reload}
            />

            <QCOrderInformationInput
              entry={entry}
              myUser={myUser}
              statusIndex={5}
              reload={reload}
            />

            <QCTrackingNumberInput
              entry={entry}
              myUser={myUser}
              statusIndex={6}
              reload={reload}
              trackingCount={1}
            />

            <QCGoodsReceived
              entry={entry}
              myUser={myUser}
              statusIndex={7}
              reload={reload}
            />

            <QCReportListItem
              entry={entry}
              myUser={myUser}
              statusIndex={8}
              reload={reload}
            />

            <QCAcceptReportListItem
              entry={entry}
              myUser={myUser}
              statusIndex={9}
              reload={reload}
            />

            {!entry?.dateDeclined && (
              <>
                <QCReleaseGoods
                  entry={entry}
                  myUser={myUser}
                  statusIndex={10}
                  reload={reload}
                />
                <QCTrackingNumberInput
                  entry={entry}
                  myUser={myUser}
                  statusIndex={11}
                  reload={reload}
                  trackingCount={2}
                />
                <QCBuyerGoodsReceived
                  entry={entry}
                  myUser={myUser}
                  statusIndex={12}
                  reload={reload}
                  trackingCount={2}
                />
              </>
            )}

            <QCDeclinedInfo entry={entry} myUser={myUser} />
          </ul>
        </SimpleBar>
      </CardBody>
    </Card>
  )
}

export default Activity
