import React, { useRef, useState } from "react"
import { Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import QCAddonsModal from "./QCAddonsModal"
import { useReactToPrint } from "react-to-print"
import logolight from "assets/images/logo-light.png"

const Overview = ({ entry, printBtn }) => {
  const [addonsModal, setAddonsModal] = useState(false)
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  const getPageMargins = () => {
    return `@page { margin: 50px 120px !important; } @media print { .no-print { display: none; } }`
  }

  return (
    <Card className="">
      <CardBody className="pb-0">
        <div ref={componentRef}>
          <div className="table-responsive mt-4">
            <style>{getPageMargins()}</style>
            <img
              src={logolight}
              alt=""
              width="200"
              className="mb-4"
              style={{
                maxWidth: "200px",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            />
            <table className="table">
              <tbody>
                <tr>
                  <th scope="col">Request Number</th>
                  <td scope="col">{entry?.id}</td>
                </tr>
                <tr>
                  <th scope="col">Test Category</th>
                  <td scope="col">{entry?.qcTestName}</td>
                </tr>
                <tr className="no-print">
                  <th scope="col">Add Ons</th>
                  <td scope="col">
                    <Link to="#" onClick={_ => setAddonsModal(true)}>
                      View Add ons
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th scope="col">Part Number</th>
                  <td scope="col">{entry?.partNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Manufacturer:</th>
                  <td>{entry?.manufacturer}</td>
                </tr>
                <tr>
                  <th scope="row">Stock</th>
                  <td>{entry?.quantity} pc.</td>
                </tr>
                <tr>
                  <th scope="row">Datecodes</th>
                  <td>{entry?.dateCodes} </td>
                </tr>
                <tr>
                  <th scope="row">QC total</th>
                  <td>
                    {entry?.price
                      ? entry.price.toFixed(2) + " " + entry.currency + " *"
                      : "-"}{" "}
                  </td>
                </tr>
                {entry?.additionalCosts && (
                  <tr>
                    <th scope="row">Additional Costs</th>
                    <td>
                      {entry?.additionalCosts + " " + entry.currency + " *"}
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row">Duration</th>
                  <td>{entry?.workingDays ?? "-"} working Days</td>
                </tr>
                <tr>
                  <th scope="row">Offer Number</th>
                  <td>{entry?.offerNumber ?? "-"}</td>
                </tr>
                <tr>
                  <th scope="col">Buyer</th>
                  <td scope="col">
                    {entry?.requestor?.name}{" "}
                    {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                  </td>
                </tr>
                <tr>
                  <th scope="col">Seller</th>
                  <td scope="col">
                    {entry?.seller?.name ?? "-"}{" "}
                    {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                  </td>
                </tr>
                <tr>
                  <th scope="col">Labor</th>
                  <td scope="col">
                    {entry?.qcLabor?.name ?? "-"}{" "}
                    {/* <i
                    className="bx bx-info-circle ms-2 font-size-18"
                    style={{ cursor: "pointer" }}
                    onClick={_ => setCompanyInfoModal(true)}
                  /> */}
                  </td>
                </tr>
                <tr>
                  <th scope="col">IPN</th>
                  <td scope="col">{entry?.internalPart}</td>
                </tr>
                <tr>
                  <th scope="col">Internal Order Number</th>
                  <td scope="col">{entry?.internalOrderNumber ?? "-"}</td>
                </tr>
                <tr>
                  <th scope="col">Location Buyer</th>
                  <td scope="col">
                    {entry?.requestor?.street}
                    <br />
                    {entry?.requestor?.postalCode +
                      " " +
                      entry?.requestor?.city}{" "}
                    <br />
                    {entry?.requestor?.country +
                      ", " +
                      entry?.requestor?.region}
                  </td>
                </tr>
                <tr>
                  <th scope="col">Location Seller</th>
                  <td scope="col">
                    {entry?.seller?.street}
                    <br />
                    {entry?.seller
                      ? entry?.seller?.postalCode + " " + entry?.seller?.city
                      : entry?.requestor?.postalCode +
                        " " +
                        entry?.requestor?.city}{" "}
                    <br />
                    {entry?.seller
                      ? entry?.seller?.country + ", " + entry?.seller?.region
                      : entry?.requestor?.country +
                        " " +
                        entry?.requestor?.region}
                  </td>
                </tr>
                <tr>
                  <th scope="col">Location QC Lab</th>
                  <td scope="col">
                    {entry?.qcLabor?.street}
                    <br />
                    {entry?.qcLabor?.postalCode +
                      " " +
                      entry?.qcLabor?.city}{" "}
                    <br />
                    {entry?.qcLabor?.country + " " + entry?.qcLabor?.region}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Notes:</th>
                  <td>{entry?.notes ? entry.notes : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-center">
            <span className="ms-2 text-muted">* All prices excluding VAT</span>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          {entry?.partRequestID && (
            <Link
              className="btn btn-primary me-2"
              to={"/requests?selected=" + entry?.partRequestID}
              target="_blank"
              rel="noreferrer"
            >
              Open Part-Request
            </Link>
          )}
          {printBtn && (
            <Link
              className="btn btn-primary ms-2"
              // to={"/qc-print/" + entry?.id}
              to="#"
              onClick={handlePrint}
              // target="_blank"
              // rel="noreferrer"
            >
              Print Request
            </Link>
          )}
        </div>
      </CardBody>
      <QCAddonsModal
        entry={entry}
        show={addonsModal}
        toggle={_ => setAddonsModal(false)}
      />
    </Card>
  )
}

export default Overview
