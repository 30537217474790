import React, { useState } from "react"
import { Col, Collapse, Row, Table, TabPane } from "reactstrap"
import classnames from "classnames"

const QCTypeTab = ({ nextTab, request, setRequest }) => {
  const [activeCategory, setActiveCategory] = useState(null)
  const toggleCategory = category => {
    setActiveCategory(activeCategory === category ? null : category)
  }

  const tests = [
    {
      id: 1,
      name: "Small",
      specification: [
        { type: "Documentation & Packaging Inspection", value: "All" },
        { type: "External Visual Inspection", value: "TBA*" },
        { type: "X-Ray", value: "TBA*" },
        { type: "XRF (Lead finish)", value: "TBA*" },
        { type: "Repacking, handling & shipping", value: "All" },
        { type: "Average Costs", value: "240 USD - 305 USD", color: "primary" },
        {
          type: "Lead time (receiving to tests finished)",
          value: "2-3 working days",
          color: "primary",
        },
      ],
      disclaimer:
        "* QC-Lab will recommend sample size dependent on the LOT-Size",
    },
    {
      id: 2,
      name: "Medium",
      specification: [
        { type: "Documentation & Packaging Inspection", value: "All" },
        { type: "External Visual Inspection", value: "TBA*" },
        { type: "X-Ray", value: "TBA*" },
        { type: "XRF (Lead finish)", value: "TBA*" },
        { type: "Heated Chemical Test (destructive)", value: "TBA*" },
        { type: "Decapsulation (destructive)", value: "TBA*" },
        { type: "Repacking, handling & shipping", value: "All" },
        { type: "Average Costs", value: "430 USD - 520 USD", color: "primary" },
        {
          type: "Lead time (receiving to tests finished)",
          value: "2-3 working days",
          color: "primary",
        },
      ],
      disclaimer:
        "* QC-Lab will recommend sample size dependent on the LOT-Size",
    },
    {
      id: 3,
      name: "AS6081",
      specification: [
        { type: "Documentation & Packaging Inspection", value: "All" },
        { type: "External Visual Inspection", value: "122" },
        { type: "X-Ray", value: "45" },
        { type: "XRF (Lead finish)", value: "3" },
        { type: "Heated Chemical Test (destructive)", value: "3" },
        { type: "Decapsulation (destructive)", value: "3" },
        { type: "Repacking, handling & shipping", value: "All" },
        {
          type: "Avarage Costs each DC:",
          value: "695 USD - 800 USD",
          color: "primary",
        },
        {
          type: "Lead time (receiving to tests finished)",
          value: "3-4 working days",
          color: "primary",
        },
      ],
      disclaimer: "",
    },
  ]

  return (
    <TabPane tabId={1}>
      <Row>
        <Col lg="12">
          <h5 className="font-size-14 mb-4">Request Type</h5>

          <p>
            Please select a Package. The details of each package are listed
            below:{" "}
          </p>

          <div>
            <select
              className="form-select"
              style={{ maxWidth: "250px" }}
              value={request?.qcTestID}
              defaultValue={""}
              onChange={e =>
                setRequest({ ...request, qcTestID: e.target.value })
              }
            >
              <option value="" disabled>
                -
              </option>
              <option value="1">Small</option>
              <option value="2">Medium</option>
              <option value="3">AS6081</option>
            </select>
          </div>

          <div className="accordion accordion-flush mt-3">
            {tests.map(category => (
              <div className="accordion-item" key={category?.name}>
                <h2
                  className="accordion-header"
                  id={`heading${category?.name}`}
                >
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: activeCategory !== category?.name,
                    })}
                    type="button"
                    onClick={() => toggleCategory(category?.name)}
                    style={{ cursor: "pointer" }}
                  >
                    {category?.name}
                  </button>
                </h2>
                <Collapse
                  isOpen={activeCategory === category?.name}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="mt-3">
                      <h5 className="mb-3">Specifications :</h5>

                      <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            {category.specification.map((specification, i) => (
                              <tr
                                key={i}
                                className={"bg-soft bg-" + specification.color}
                              >
                                <th scope="row" style={{ width: "400px" }}>
                                  {specification.type}
                                </th>
                                <td>{specification.value}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      <p className="mt-2 ms-3">{category.disclaimer}</p>
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-primary"
          type="button"
          disabled={!request?.qcTestID}
          onClick={_ => nextTab(1)}
        >
          Next
        </button>
      </div>
    </TabPane>
  )
}

export default QCTypeTab
