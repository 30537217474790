import React, { useEffect, useState } from "react"
import { Modal, ModalBody } from "reactstrap"
import { Rating } from "react-simple-star-rating"
import { displayMessage } from "helpers/misc_helpers"
import { useLoading } from "helpers/custom_hooks"
import { CreateQCRating, CreateRating } from "helpers/backend_helper"

const QCRatingModal = ({ entry, myUser }) => {
  const [ratingRequestor, setRatingRequestor] = useState(0)
  const [ratingSeller, setRatingSeller] = useState(0)
  const [ratingQcLab, setRatingQcLab] = useState(0)
  const [show, setShow] = useState(false)
  const [error, setError] = useState()
  const setLoading = useLoading()
  const IsUserQCLabor = () => myUser?.company?.id == entry?.qcLaborID
  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID
  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  useEffect(() => {
    if (
      (entry?.statusIndex == 12 ||
        entry?.dateDeclined ||
        entry?.statusIndex == -1) &&
      ((isUserSeller() && !entry?.dateSellerRated) ||
        (isUserRequestor() && !entry?.dateRequestorRated) ||
        (IsUserQCLabor() && !entry?.dateQCLaborRated))
    )
      setShow(true)
  }, [entry])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await CreateQCRating({
        requestID: entry.id,
        ratingQcLab,
        ratingSeller,
        ratingRequestor,
      })
      window.location.reload()
    } catch (error) {
      setLoading(false)
      displayMessage("An unexpected error happened!", setError)
    }
  }

  return (
    <Modal size="sm" isOpen={show} toggle={_ => setShow(false)} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5 text-center">
          <p className="text-muted font-size-16 mb-3">
            Please provide feedback on your partner's performance
          </p>

          {!isUserRequestor() && (
            <>
              <h5 className="mt-3">Buyer Rating:</h5>
              <Rating
                onClick={rate => setRatingRequestor(rate)}
                fillClassName="text-primary"
              />
            </>
          )}
          {!isUserSeller() && (
            <>
              <h5 className="mt-3">Seller Rating:</h5>
              <Rating
                onClick={rate => setRatingSeller(rate)}
                fillClassName="text-primary"
              />
            </>
          )}
          {!IsUserQCLabor() && (
            <>
              <h5 className="mt-3">QC Lab Rating:</h5>
              <Rating
                onClick={rate => setRatingQcLab(rate)}
                fillClassName="text-primary"
              />
            </>
          )}

          <div className="hstack gap-2 justify-content-center mb-0 mt-4">
            <button
              type="button"
              className="btn btn-outline-secondary w-100"
              onClick={_ => setShow(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-outline-primary w-100"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default QCRatingModal
