import React from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"

const QCAcceptReportListItem = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()

  const handleDecistion = async decision => {
    setLoading(true)
    try {
      if (decision == 1) {
        entry.dateAccepted = new Date()
        entry.additionalTests = true
        entry.notifySeller = true

        if (isUserSeller() && isUserRequestor()) {
          entry.notifySeller = null
          entry.statusIndex--
        }
      }
      if (decision == 2) {
        entry.dateAccepted = new Date()
        entry.statusIndex++
      }
      if (decision == 3) {
        entry.dateDeclined = new Date()
        entry.statusIndex++
      }

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const handleSellerDecision = async decision => {
    setLoading(true)
    try {
      if (decision == 1) {
        entry.notifySeller = null
        entry.statusIndex--
      }

      if (decision == 2) {
        entry.dateDeclined = new Date()
        entry.statusIndex++
      }

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const hasRequestorAccepted = () => entry?.dateAccepted

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID
  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID

  return (
    <>
      {entry?.dateAccepted && entry?.statusIndex >= statusIndex ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry?.dateAccepted).format("DD.MM.YYYY")}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Report accepted by buyer</div>
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 &&
        isUserRequestor() &&
        !hasRequestorAccepted() && (
          <>
            <p className="ms-3">
              Attached you can find the QC report. Please confirm acceptance of
              the report to proceed with the shipment. Or push reject to return
              the goods to the seller.
            </p>
            {entry?.additionalCosts > 0 && !entry?.additionalTests && (
              <button
                type="button"
                onClick={_ => handleDecistion(1)}
                className="d-block btn btn-primary mb-2 ms-3"
              >
                Accept Report and proceed testing
              </button>
            )}

            <button
              type="button"
              onClick={_ => handleDecistion(2)}
              className="d-block btn btn-info mb-2 ms-3"
            >
              {entry?.additionalCosts && !entry?.additionalTests
                ? "Accept Report without further testing"
                : "Accept Report"}
            </button>
            <button
              type="button"
              onClick={_ => handleDecistion(3)}
              className="btn btn-danger mb-3 ms-3"
            >
              Reject Report
            </button>
          </>
        )
      )}

      {entry?.statusIndex == statusIndex - 1 &&
        isUserSeller() &&
        !isUserRequestor() &&
        entry?.additionalTests &&
        entry?.additionalCosts > 0 && (
          <>
            <p className="ms-3">
              The QC lab suggests addional tests and the buyer accepted those
              tests. Please accept or decline the additional tests.
            </p>
            <button
              type="button"
              onClick={_ => handleSellerDecision(1)}
              className="d-block btn btn-primary mb-2 ms-3"
            >
              Accept additional tests
            </button>

            <button
              type="button"
              onClick={_ => handleSellerDecision(3)}
              className="btn btn-danger mb-3 ms-3"
            >
              Reject additional tests
            </button>
          </>
        )}
    </>
  )
}

export default QCAcceptReportListItem
