import React, { useState } from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"

const QCBuyerGoodsReceived = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()
  const [error, setError] = useState()

  const handleSave = async _ => {
    setLoading(true)
    try {
      entry.dateBuyerReceivedGoods = new Date()
      entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  return (
    <>
      {entry?.dateBuyerReceivedGoods ? (
        <li className="event-list">
          <div className={"event-timeline-dot"}>
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry?.dateBuyerReceivedGoods).format("DD.MM.YYYY")}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Buyer has received the goods. QC is now closed</div>
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 && (
          <>
            {isUserRequestor() && (
              <>
                <p className="mx-3 ms-4">
                  When the package arrives at your company, please mark the
                  request as “Arrived” using the button below.
                </p>
                <button
                  type="button"
                  onClick={handleSave}
                  className="btn btn-primary ms-3"
                >
                  Mark as arrived
                </button>
              </>
            )}

            {!isUserRequestor() && (
              <p className="ms-3">
                Please wait until the buyer receives the goods.
              </p>
            )}
          </>
        )
      )}
    </>
  )
}

export default QCBuyerGoodsReceived
