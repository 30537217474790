import { Form, Formik } from "formik"
import FormikInputField from "helpers/FormikInputField"
import { useLoading } from "helpers/custom_hooks"
import React, { useState } from "react"
import { Alert, Col, Row, TabPane } from "reactstrap"
import { displayMessage } from "helpers/misc_helpers"
import { CreateQCRequest, ResetQCRequest } from "helpers/backend_helper"

const QCRequestDataTab = ({ nextTab, request }) => {
  const fields = [
    {
      fieldName: "internalPart",
      label: "IPN",
      component: FormikInputField,
    },
    {
      fieldName: "partNumber",
      label: "Manufacturer Part Number",
      component: FormikInputField,
    },
    {
      fieldName: "manufacturer",
      label: "Manufacturer",
      component: FormikInputField,
    },
    {
      fieldName: "quantity",
      label: "Quantity",
      component: FormikInputField,
    },
    {
      fieldName: "notes",
      label: "Notes",
      component: FormikInputField,
    },
    {
      fieldName: "dateCodes",
      label: "Datecodes",
      component: FormikInputField,
    },
  ]

  const setLoading = useLoading()
  const [error, setError] = useState()
  const initialValues = {
    ...request,
    internalPart: request?.internalPart ?? "",
    manufacturer: request?.manufacturer ?? "",
    notes: request?.notes ?? "",
    internalOrderNumber: "",
    partNumber: request?.partNumber ?? "",
    quantity: request?.quantity ?? "",
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true)
      if (values.id) await ResetQCRequest(values)
      else await CreateQCRequest(values)
      nextTab(1)
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unhandled exception happened!", setError)
    } finally {
      setLoading(false)
      setSubmitting(false)
    }
  }

  return (
    <TabPane tabId={4}>
      <div className="row pt-4 px-5">
        <Col lg="6">
          <div className="">
            <div>
              {error && <Alert color="danger">{error}</Alert>}

              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors, touched }) => (
                  <Form>
                    <Row>
                      {fields.map((field, key) => (
                        <Col lg="6" key={key}>
                          <field.component
                            fieldName={field.fieldName}
                            label={field.label}
                            errors={errors}
                            touched={touched}
                          />
                        </Col>
                      ))}
                    </Row>
                    <button type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Col>
      </div>
    </TabPane>
  )
}

export default QCRequestDataTab
