import { Link } from "react-router-dom"
import {
  formatDate,
  IsQCRequestUnread,
  mapQCStatus,
} from "helpers/misc_helpers"
import React from "react"
import { Badge } from "reactstrap"
import moment from "moment"

export const columns = myUser => [
  {
    dataField: "isReadByQCLabor",
    text: "",
    sort: false,
    formatter: (_, row) => (
      <i
        className={
          "mdi mdi-circle text-success font-size-10 " +
          (IsQCRequestUnread(myUser, row) ? "" : "opacity-0")
        }
      />
    ),
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "id",
    text: "#",
    sort: true,
  },
  {
    dataField: "qcTestName",
    text: "Test",
    sort: true,
  },
  {
    dataField: "partNumber",
    text: "Part no.",
    sort: true,
  },
  {
    dataField: "manufacturer",
    text: "Manufacturer",
    sort: true,
  },
  {
    dataField: "quantity",
    text: "Quantity",
    sort: true,
    formatter: cell => <>{cell} pc.</>,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "statusIndex",
    text: "Status",
    sort: true,
    formatter: (cell, row) => (
      <QCStatus status={cell} declined={row.dateDeclined} />
    ),
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "dateCreated",
    text: "Creation Date",
    sort: true,
    formatter: cell => <>{moment(cell).format("DD.MM.YYYY")}</>,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "dateUpdated",
    text: "Last Update",
    sort: true,
    formatter: cell => <>{cell ? moment(cell).format("DD.MM.YYYY") : "-"}</>,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
  },
  {
    dataField: "menu",
    text: "",
    align: () => "end",
    formatter: (cellContent, row) => (
      <>
        <Link to={`/qc-requests/${row.id}`} className="btn btn-primary ">
          Open
        </Link>
      </>
    ),
  },
]

const QCStatus = ({ status, declined }) => {
  const qcStatus = mapQCStatus(status, declined)
  return (
    <Badge
      color={qcStatus?.color}
      className={"py-1 px-2 badge-soft-" + qcStatus?.color}
    >
      {qcStatus?.label}
    </Badge>
  )
}
