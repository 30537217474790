import moment from "moment"
import React from "react"

const QCDeclinedInfo = ({ entry, myUser }) => {
  const IsUserQCLabor = () => myUser?.company?.id == entry?.qcLaborID
  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID
  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  return (
    <>
      {entry?.dateDeclined && (
        <li className="event-list">
          <div className="event-timeline-dot ">
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry.dateDeclined).format("DD.MM.YYYY")}{" "}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Report Declined</div>
            </div>
          </div>
          <div className="mt-2">
            {IsUserQCLabor() && (
              <p className="mb-0" style={{ cursor: "pointer" }}>
                The goods are not acceptable for the Buyer. The Seller has been
                informed to provide you shipping details or information within 5
                working days. Otherwise, you are obliged to send the goods to
                Seller premises and charge the shipping and the inspection costs
                directly to the Seller.
                <br />
                <br />
                For further discussions regarding the shipment of the goods,
                please use the chat on the right side.
              </p>
            )}
            {isUserSeller() && (
              <p className="mb-0" style={{ cursor: "pointer" }}>
                The QC report has not confirmed the required quality of the
                goods. The Buyer has rejected the goods. Please send the
                shipping details to the QC lab to start the shipment to your
                premises. If you don’t want the goods to be returned to your
                premises, please inform the QC lab about your decision. In case
                you don’t inform the QC lab within 5 days, the goods will be
                returned to your premises at your costs. As agreed, the QC lab
                will also charge you the costs for the quality inspection.
                <br />
                <br />
                For further discussions regarding the shipment of the goods,
                please use the chat on the right side.
              </p>
            )}
            {isUserRequestor() && (
              <p className="mb-0" style={{ cursor: "pointer" }}>
                The inspection report is not accepted. According to the agreed
                terms and conditions, the costs for the inspection are at
                Sellers expenses. You will receive a separate invoice from the
                QC lab.
                <br />
                <br />
                For further discussions regarding the shipment of the goods,
                please use the chat on the right side.
              </p>
            )}
          </div>
        </li>
      )}
    </>
  )
}

export default QCDeclinedInfo
