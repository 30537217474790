import { qcAddons } from "common/constants"
import React, { useEffect, useState } from "react"
import { Modal, ModalBody, Table } from "reactstrap"

function QCAddonsModal({ entry, show, toggle }) {
  const [selectedAddons, setSelectedAddons] = useState([])

  useEffect(() => {
    if (entry?.addons) {
      const addonsArray = entry.addons.split(",").map(addon => parseInt(addon))
      setSelectedAddons(qcAddons.filter(a => addonsArray.includes(a.id)))
    }
  }, [entry])

  return (
    <Modal size="m" isOpen={show} toggle={toggle} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 pb-3">
          <button
            type="button"
            onClick={toggle}
            className="btn-close position-absolute end-0 top-0 m-3"
          />
          <h5 className="mb-3">Add Ons :</h5>
          <div className="table-responsive">
            <Table className="table mb-0 table-bordered">
              <thead>
                <tr>
                  <th>Process</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {selectedAddons.map(addon => (
                  <tr key={addon.id} onClick={() => handleRowClick(addon.id)}>
                    <th scope="row" style={{ width: "400px" }}>
                      {addon.process}
                    </th>
                    <td>{addon.quantity}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={2} className="text-center">
                    {(selectedAddons?.length === 0 || !selectedAddons) &&
                      "No Addons selected!"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default QCAddonsModal
