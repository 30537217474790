import { Formik, Form, Field } from "formik"
import FormikInputField from "helpers/FormikInputField"
import {
  CreateRequest,
  GetMyLocations,
  GetMyUser,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { Alert, Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap"
import { displayMessage } from "helpers/misc_helpers"
import { useNavigate } from "react-router-dom"
import MessageModal from "components/Common/MessageModal"
import FormikPriceInputField from "helpers/FormikPriceInputField"

const RequestSection = ({ part }) => {
  const [locations, setLocations] = useState()
  const [user, setUser] = useState()
  const [error, setError] = useState()
  const [isOwnedPart, setIsOwnedPart] = useState(false)
  const [message, setMessage] = useState("")
  const [successModal, setSuccessModal] = useState(false)
  const [userInfoModal, setUserInfoModal] = useState(false)
  const setLoading = useLoading()
  const navigate = useNavigate()

  const initialValues = {
    deliveryDate: "",
    locationID: (locations?.length > 0 ? locations[0] : null)?.id,
    quantity: "",
    price: part?.price ?? "",
    companyID: part?.company?.id,
    currency: part?.currency,
  }

  const validationSchema = Yup.object({
    deliveryDate: Yup.date().required("Please enter delivery date!"),
    quantity: Yup.number().required("Please enter quantity!"),
  })

  const loadData = async () => {
    setLoading(true)
    try {
      const result = await GetMyLocations()
      const userObj = await GetMyUser()
      setUser(userObj)
      setLocations(result)
      setIsOwnedPart(part?.company?.id == userObj?.company?.id)
    } catch (error) {
      console.log(error)
      alert("An unexpected error happened!")
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleSubmit = async values => {
    setLoading(true)
    try {
      await CreateRequest({
        ...values,
        price: values.price
          ? ("" + values.price).replace(",", ".")
          : values.price,
        sellerCompanyID: part?.company?.id,
        buyerCompanyID: user.company.id,
        partID: part.id,
        locationID: values.locationID,
        message,
      })
      if (part?.user?.accountType == "Premium") setSuccessModal(true)
      else setUserInfoModal(true)
    } catch (error) {
      console.log(error)

      if (error?.response?.status === 400)
        displayMessage(error.response.data, setError)
      else displayMessage("An unexpected error happened!", setError)
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <Col xl={7}>
        <Card>
          <CardBody>
            {error && <Alert color="danger">{error}</Alert>}
            <CardTitle>Request</CardTitle>
            <p className="card-title-desc mb-4">Fill all information below:</p>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <Row>
                    <Col>
                      <FormikInputField
                        fieldName="deliveryDate"
                        label="Delivery Date:"
                        errors={errors}
                        touched={touched}
                        fieldType="date"
                        disabled={isOwnedPart}
                      />
                    </Col>
                    <Col>
                      <div className="mb-3">
                        <Label for="basicpill-cstno-input7">
                          Shipping Address:
                        </Label>
                        <Field
                          as="select"
                          className="form-select"
                          name="locationID"
                          disabled={isOwnedPart}
                        >
                          {locations?.map((location, key) => (
                            <option key={key} value={location.id}>
                              {location.street?.substring(0, 40)}
                              {location.street?.length > 40 && "..."} | 
                              {location.city?.substring(0, 10)}...
                              {location.country}
                            </option>
                          ))}
                        </Field>
                        {errors.locationID && touched.locationID && (
                          <div className="text-danger">{errors.locationID}</div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikInputField
                        fieldName="quantity"
                        label="Quantity:"
                        errors={errors}
                        touched={touched}
                        disabled={isOwnedPart}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <FormikPriceInputField
                            fieldName="price"
                            label="Price excl. VAT (optional):"
                            errors={errors}
                            touched={touched}
                            disabled={isOwnedPart || part?.price}
                          />
                        </Col>
                      </Row>{" "}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>Message (optional): </label>
                      <textarea
                        className="form-control mb-3"
                        disabled={isOwnedPart}
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex justify-content-end align-items-center">
                        {isOwnedPart && (
                          <span className="text-danger me-2 d-block">
                            You cannot submit a request as this part belongs to
                            you.
                          </span>
                        )}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isOwnedPart}
                        >
                          Submit request
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
      <MessageModal
        show={successModal}
        displayMessage={"Request successful!"}
        onCloseClick={_ => navigate(-1)}
        color="success"
        icon="bx bx-check"
      />

      <MessageModal
        icon="bx bx-envelope"
        color="success"
        show={userInfoModal}
        displayMessage={
          "This company will be notified. As soon as they will upgrade their account they will be able to respond to your request"
        }
        onCloseClick={_ => navigate(-1)}
      />
    </React.Fragment>
  )
}

export default RequestSection
