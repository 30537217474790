import React from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Badge, Input, Nav, NavItem, NavLink } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"
import avatar1 from "../../assets/images/default-avatar.png"
import classnames from "classnames"
import { getRequestStatusColor } from "helpers/misc_helpers"

const RequestList = ({
  user,
  setSelectedStatus,
  selectedStatus,
  getCompanyInfo,
  requests,
  currentChat,
  setCurrentChat,
}) => {
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById("search-user")
    filter = input.value.toUpperCase()
    ul = document.getElementById("recent-list")
    li = ul.getElementsByTagName("li")
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0]
      txtValue = a.textContent || a.innerText
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }

  const userChatOpen = chat => {
    setCurrentChat(chat)
  }

  return (
    <div className="chat-leftsidebar me-lg-3 col-4 h-100">
      <div className="py-4 border-bottom">
        <div className="d-flex">
          <div className="align-self-center me-3">
            <img
              src={user?.company?.logoURL ?? avatar1}
              className="avatar-xs rounded-circle"
              alt=""
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="font-size-15 mt-0 mb-1">
              {user?.firstname} {user?.lastname}
            </h5>
            <p className="text-muted mb-0">{user?.company?.name ?? ""}</p>
          </div>
        </div>
      </div>

      <div className="search-box chat-search-box py-4">
        <div className="position-relative">
          <Input
            onKeyUp={searchUsers}
            id="search-user"
            type="text"
            className="form-control"
            placeholder="Search..."
          />
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>
      <Nav pills justified>
        <NavItem>
          <NavLink
            className={classnames({
              active: selectedStatus === "RFQ",
            })}
            onClick={() => {
              setSelectedStatus("RFQ")
            }}
          >
            <i className="bx bx-group font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">RFQ</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: selectedStatus === "accepted",
            })}
            onClick={() => {
              setSelectedStatus("accepted")
            }}
          >
            <i className="bx bx-book-content font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Order Processing</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: selectedStatus === "archived",
            })}
            onClick={() => {
              setSelectedStatus("archived")
            }}
          >
            <i className="bx bx-book-content font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">Archived</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: selectedStatus === "",
            })}
            onClick={() => {
              setSelectedStatus("")
            }}
          >
            <i className="bx bx-chat font-size-20 d-sm-none" />
            <span className="d-none d-sm-block">All</span>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mt-4">
        <div className="chat-leftsidebar-nav">
          <div>
            <h5 className="font-size-14 mb-3">Recent</h5>
            <ul className="list-unstyled chat-list" id="recent-list">
              <PerfectScrollbar style={{ height: "410px" }}>
                {map(requests, chat => {
                  const companyInfo = getCompanyInfo(chat)
                  const unreadMessages = chat.messages.filter(
                    m => !m.readAt && m.companyID != user.company.id
                  )

                  return (
                    <li
                      key={chat.id + chat.status}
                      className={currentChat?.id === chat.id ? "active" : ""}
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          userChatOpen(chat)
                        }}
                      >
                        <div className="d-flex">
                          <div className="align-self-center me-3">
                            <i
                              className={
                                "mdi mdi-circle text-success font-size-10 " +
                                (unreadMessages.length == 0 ? "opacity-0" : "")
                              }
                            />
                          </div>

                          <div className="align-self-center me-3">
                            {companyInfo?.logoURL ? (
                              <img
                                src={companyInfo?.logoURL}
                                className="rounded avatar-sm"
                                alt=""
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <img
                                src={avatar1}
                                className="rounded avatar-sm rounded-circle"
                                alt=""
                              />
                            )}
                          </div>

                          <div className="flex-grow-1 overflow-hidden">
                            <h5 className="text-truncate font-size-16 mb-1">
                              {chat.partNumber}{" "}
                            </h5>
                            <p className="text-truncate mb-0">
                              {companyInfo?.name}
                            </p>
                            <p className="text-truncate mb-0">
                              {
                                chat.messages[chat.messages.length - 1]
                                  ?.quantity
                              }{" "}
                              pc.
                              {chat.messages[chat.messages.length - 1]?.price >
                                0 &&
                                " x " +
                                  chat.messages[
                                    chat.messages.length - 1
                                  ].price.toFixed(5) +
                                  " " +
                                  chat.messages[chat.messages.length - 1]
                                    .currency}
                            </p>
                          </div>
                          <div className="font-size-11 d-flex flex-column align-items-end">
                            <p className="text-right mb-2 me-1">
                              {chat.seller.id == user.company.id
                                ? "incoming"
                                : "outgoing"}
                            </p>
                            <div>
                              <Badge
                                color="info"
                                className="me-1 p-2 badge-soft-danger"
                                style={{ opacity: chat.qcRequestID ? 1 : 0 }}
                              >
                                QC
                              </Badge>
                              <Badge
                                color="primary"
                                className={
                                  "me-1 p-2 " +
                                  getRequestStatusColor(
                                    chat.isArchived ? "archived" : chat.status
                                  )
                                }
                              >
                                {chat.isArchived ? "archived" : chat.status}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  )
                })}
                {requests.length == 0 && (
                  <li className="active">
                    <div className="d-flex">
                      <div className="align-self-center me-3"></div>
                      <p className="mt-2">Currently no requests!</p>
                    </div>
                  </li>
                )}
              </PerfectScrollbar>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestList
