import { UpdateQCRequest } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const DeclineQCOfferModal = ({ entry, show, toggle, myUser, reload }) => {
  const setLoading = useLoading()
  const [offerDeclinedMessage, setOfferDeclinedMessage] = useState("")

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  const handleDeclineOffer = async () => {
    setLoading(true)
    try {
      if (isUserRequestor()) entry.dateOfferDeclinedRequestor = new Date()
      else entry.dateOfferDeclinedSeller = new Date()

      entry.offerDeclinedMessage = offerDeclinedMessage
      entry.statusIndex = -1

      await UpdateQCRequest(entry)
      toggle()
      reload()
    } catch (error) {
      alert("An unexpected Error happened")
      setLoading(false)
      console.log(error)
    }
  }

  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 pt-5 text-center">
          <div className="avatar-md mb-4 mx-auto">
            <div
              className={`avatar-title bg-danger text-danger bg-opacity-10 font-size-24 rounded-4 p-2`}
            >
              <i className="bx bx-error font-size-28 text-danger"></i>
            </div>
          </div>
          <h5 className="mb-3">
            If you decline the offer, the whole QC request will be canceled. Do
            you want to proceed?
          </h5>
          <p className=" mb-1">
            Please enter a message to all parties involved
          </p>
          <textarea
            className="form-control mb-3"
            value={offerDeclinedMessage}
            onChange={e => setOfferDeclinedMessage(e.target.value)}
          />

          <div className="hstack gap-2 justify-content-center mb-0">
            <button
              type="button"
              className="btn btn-secondary w-50"
              onClick={toggle}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger w-50"
              onClick={handleDeclineOffer}
            >
              Decline Offer
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeclineQCOfferModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeclineQCOfferModal
