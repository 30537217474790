import React, { useState } from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"
import DeclineQCOfferModal from "components/Common/DeclineQCOfferModal"
import { Link } from "react-router-dom"

const QCAcceptOfferListItem = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()
  const [declineQCOfferModal, setDeclineQCOfferModal] = useState(false)

  const handleAcceptOffer = async () => {
    setLoading(true)
    try {
      if (isUserRequestor()) entry.dateOfferAcceptedRequestor = new Date()

      if (isUserSeller()) entry.dateOfferAcceptedSeller = new Date()

      entry.statusIndex++

      if (isUserRequestor() && isUserSeller()) entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const getAcceptedDate = () => {
    const parsedDate1 = new Date(entry.dateOfferAcceptedRequestor)
    const parsedDate2 = new Date(entry.dateOfferAcceptedSeller)

    // Determine the more recent date
    const biggerDate = parsedDate1 > parsedDate2 ? parsedDate1 : parsedDate2

    return moment(biggerDate).format("DD.MM.YYYY")
  }

  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID

  return (
    <>
      {entry?.dateOfferAcceptedRequestor && entry?.dateOfferAcceptedSeller ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {getAcceptedDate()}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Offer accepted by both parties</div>
            </div>
          </div>
        </li>
      ) : (
        <>
          {((entry?.statusIndex == statusIndex - 2 && isUserRequestor()) ||
            (entry?.statusIndex == statusIndex - 1 && isUserSeller())) && (
            <>
              <p className="ms-3">
                <strong>
                  You have received the quotation for the chosen QC inspection.
                  Please confirm to initialize the further process.
                </strong>
              </p>
              <p className="ms-3">
                <strong className="text-danger">Important:</strong>
                <br />
                The costs for the inspection, transport, handling, etc. are{" "}
                <u>covered by the buyer.</u> Unless the results of the
                inspection report are unveiling insufficient quality. In this
                case, the quality inspection costs are to be covered by the
                seller.
              </p>
              <button
                type="button"
                onClick={handleAcceptOffer}
                className="btn btn-primary ms-3"
              >
                Accept
              </button>
              <button
                type="button"
                onClick={_ => setDeclineQCOfferModal(true)}
                className="btn btn-danger ms-3"
              >
                Decline
              </button>
              {isUserRequestor() && (
                <Link
                  type="button"
                  to={"/qc-request-wizard?id=" + entry?.id}
                  className="btn btn-warning ms-3"
                >
                  Edit request
                </Link>
              )}
            </>
          )}
        </>
      )}
      <DeclineQCOfferModal
        entry={entry}
        show={declineQCOfferModal}
        toggle={_ => setDeclineQCOfferModal(false)}
        myUser={myUser}
        reload={reload}
      />
    </>
  )
}

export default QCAcceptOfferListItem
