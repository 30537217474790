import React from "react"
import countries from "i18n-iso-countries"
import enLocale from "i18n-iso-countries/langs/en.json"
import moment from "moment"
import { jwtDecode } from "jwt-decode"
import {
  GetPermissions,
  GetUnreadMessages,
  GetUnreadQCRequests as GetUnreadQCRequestsCount,
} from "./backend_helper"

export const displayMessage = (message, setMessage) => {
  setMessage(message)

  setTimeout(() => {
    setMessage()
  }, 3000)
}

export const getCountryList = () => {
  countries.registerLocale(enLocale)
  const countryObj = countries.getNames("en")

  return Object.entries(countryObj).map(([key, value]) => {
    return { code: key, name: value }
  })
}

export class UserInfoError extends Error {
  constructor(msg) {
    super(msg)
  }
}

export const currencyFormatter = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 4, // specifies the minimum number of decimal digits
  maximumFractionDigits: 4, // specifies the maximum number of decimal digits
})

export const formatDate = date => {
  return moment(date).local().format("DD.MM.YYYY HH:mm")
}

export const IsAdminUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)

      return user.userType == "admin"
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const IsQCLaborUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)
      return user.companyType == "QC-Labor"
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const IsPremiumUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) {
      const user = jwtDecode(authUser)

      return user.accountType == "Premium"
    } else return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const IsQCModuleEnabled = async _ => {
  try {
    const permissions = await GetPermissions()

    return permissions?.qcModule == 1
  } catch (error) {
    console.log(error)
    return false
  }
}

export const GetLoggedOnUser = _ => {
  try {
    const authUser = localStorage.getItem("authUser")

    if (authUser) return jwtDecode(authUser)
  } catch (error) {
    console.log(error)
  }
}

export const getRequestStatusColor = status => {
  switch (status) {
    case "accepted":
      return "badge-soft-primary"
    case "declined":
      return "badge-soft-danger"
    case "RFQ":
      return "badge-soft-info"
    case "archived":
      return "badge-soft-dark"
    default:
      return "" // Default class or leave empty
  }
}

export const updateCountUnreadMessages = async setCountMessages => {
  const messages = await GetUnreadMessages()
  setCountMessages(messages.length)
}

export const updateUnreadQCRequests = async setUnreadQCRequestsCount => {
  const unreadCount = await GetUnreadQCRequestsCount()

  setUnreadQCRequestsCount(unreadCount)
}

export const mapQCStatus = (status, declined) => {
  status = parseInt(status)
  switch (status) {
    case -1:
      return { color: "danger", label: "offer declined" }
    case 1:
      return { color: "warning", label: "open" }

    case 2:
      return { color: "warning", label: "offer provided" }

    case 3:
      return { color: "success", label: "buyer accepted" }

    case 4:
      return { color: "success", label: "offer accepted" }

    case 5:
      return { color: "info", label: "In Transit" }

    case 6:
      return { color: "success", label: "Goods arrived" }

    case 7:
      return { color: "danger", label: "In Review" }

    case 8:
      return { color: "success", label: "Report available" }

    case 9:
      return declined
        ? { color: "danger", label: "Report declined" }
        : { color: "success", label: "Report accepted" }

    case 10:
      return { color: "warning", label: "Waiting for Shipping" }

    case 11:
      return { color: "info", label: "In Transit" }

    case 12:
      return { color: "dark", label: "Closed" }
  }
}

export const IsUserQCLabor = (myUser, entry) =>
  myUser?.company?.id == entry?.qcLaborID

export const isUserSeller = (myUser, entry) =>
  myUser?.company?.id == entry?.sellerCompanyID

export const isUserRequestor = (myUser, entry) =>
  myUser?.company?.id == entry?.requestorCompanyID

export const IsQCRequestUnread = (myUser, entry) => {
  return (
    (isUserSeller(myUser, entry) && entry?.isReadBySeller != 1) ||
    (isUserRequestor(myUser, entry) && entry?.isReadByRequestor != 1) ||
    (IsUserQCLabor(myUser, entry) && entry?.isReadByQCLabor != 1)
  )
}
