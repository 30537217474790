import React from "react"
import moment from "moment"

const QCRequestCreated = ({ entry, statusIndex }) => {
  return (
    <li
      className={
        "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
      }
    >
      <div
        className={
          "event-timeline-dot " +
          (entry?.statusIndex == 1 ? "bx-fade-right" : "")
        }
      >
        <i className="bx bx-right-arrow-circle font-size-18"></i>
      </div>
      <div className="d-flex">
        <div className="flex-shrink-0 me-3">
          <h5 className="font-size-14">
            {moment(entry?.dateCreated).format("DD.MM.YYYY")}{" "}
            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
          </h5>
        </div>
        <div className="flex-grow-1">
          <div>
            Request Created{" "}
            {entry?.statusIndex == 1
              ? " - waiting for offer provided by QC Lab"
              : ""}
          </div>
        </div>
      </div>
    </li>
  )
}

export default QCRequestCreated
