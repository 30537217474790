export const LOGIN = "/users/login"

export const PERMISSIONS = "/users/permissions"

export const REGISTER = "/users/register"

export const CONFIRM_EMAIL = "/users/confirm-email"

export const USERS = "/users"

export const RESET_PASSWORD = "/users/resetPassword"

export const CHANGE_EMAIL = "/users/change-email"

export const CHANGE_EMAIL_CONFIRMATION = "/users/change-email-confirmation"

export const MY_USER = "/users/myAccount"

export const DELETE_USER = "/users/deletion/"

export const BILLING = "/users/billing/"

export const UPGRADE = "/users/requestUpgrade"

export const DOWNGRADE = "/users/requestDowngrade"

export const COMPANIES = "/companies"

export const UPDATELOGO = "/companies/updatelogo"

export const LOCATIONS = "/locations"

export const PART_LIST = "/partlist"

export const MY_PART_LIST = "/partlist/myParts"

export const UPLOAD_LOGOS = "/uploads/logos"

export const UPLOAD_CHATATTACHMENTS = "/uploads/chat-attachments"

export const UPLOAD_QCATTACHMENTS = "/uploads/qc-attachments"

export const UPLOAD_QCCHATATTACHMENTS = "/uploads/qc-comments-attachments"

export const MY_LOCATIONS = "/locations"

export const REQUESTS = "/requests"

export const SAVEDPARTS = "/savedParts"

export const WATCH_LIST = "/watchlist"

export const RATINGS = "/ratings"

export const QC_Tests = "/qctests"

export const QC_LABORS = "/qclabors"

export const QC_REQUESTS = "/qcrequests"

export const QC_COMMENTS = "/qccomments"
