export const qcAddons = [
  { id: 1, process: "Baking", quantity: "All", price: "$50.00 each day" },
  { id: 2, process: "Cross Section", quantity: "1", price: "$100.00" },
  {
    id: 3,
    process: "Decapsulation (destructive)",
    quantity: "1",
    price: "$65.00 ",
  },
  {
    id: 4,
    process: "Electrical / Functional Test",
    quantity: "TBA",
    price: "By Quote",
  },
  {
    id: 5,
    process: "HCT (Heated Chemical Test)",
    quantity: "1",
    price: "$55.00 ",
  },
  {
    id: 6,
    process: "Re-Taping (Tube, Tray or Bulk on Tape)",
    quantity: "Re-taping work",
    price: "$0.02-$0.09 each piece",
  },
  {
    id: 7,
    process: "Solderability test for BGA(if DC>2years, destructive)",
    quantity: "1",
    price: "$170.00",
  },
  {
    id: 8,
    process: "Solderability test for Dip&Look (if DC>2years, destructive)",
    quantity: "1",
    price: "$35.00",
  },
  { id: 9, process: "X-Ray Analysis", quantity: "< 5", price: "$50.00" },
  { id: 10, process: "XRF", quantity: "1", price: "$70.00 " },
]
