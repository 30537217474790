import React from "react"
import QCOfferFileDrop from "./QCOfferFileDrop"
import moment from "moment"

const QCOfferListItem = ({ entry, myUser, statusIndex, reload }) => {
  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID
  const isUserRequestor = () => myUser?.company?.id == entry?.requestorCompanyID

  return (
    <>
      {entry?.offerFileURL &&
      ((entry.statusIndex != statusIndex && isUserSeller()) ||
        isUserRequestor()) ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <a
            href={entry?.offerFileURL}
            target="_blank"
            rel="noreferrer"
            className="text-dark"
          >
            <div
              className={
                "event-timeline-dot " +
                (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
              }
            >
              <i className="bx bx-right-arrow-circle font-size-18"></i>
            </div>
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <h5 className="font-size-14">
                  {moment(entry?.dateOfferUploaded).format("DD.MM.YYYY")}{" "}
                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                </h5>
              </div>
              <div className="flex-grow-1">
                <div>Offer provided by QC Lab</div>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <div style={{ width: "45px" }}>
                <div className="avatar-sm">
                  <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-24">
                    <i className="bx bxs-file-doc" />
                  </span>
                </div>
              </div>
              <div className=" ms-4">
                <h5 className="font-size-14 mb-1">QC Offer</h5>
                <small>Klick To Download</small>
              </div>
              <div className="text-center ms-4">
                <i className="bx bx-download h3 m-0" />
              </div>
            </div>
          </a>
          <div className="mt-4">
            <b>Seller and Buyer Status:</b>
          </div>
          <div className="d-flex mt-2">
            <div>Buyer Confirmation: </div>
            <div className="ms-2">
              {entry?.dateOfferAcceptedRequestor ? (
                <span className="badge bg-success px-2 py-1">Confirmed</span>
              ) : entry?.dateOfferDeclinedRequestor ? (
                <span className="badge bg-danger px-2 py-1">Declined</span>
              ) : (
                <span className="badge bg-warning px-2 py-1">
                  {entry?.dateOfferDeclinedSeller ? "-" : "Pending"}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div>Seller Confirmation: </div>
            <div className="ms-2">
              {entry?.dateOfferAcceptedSeller ? (
                <span className="badge bg-success px-2 py-1">Confirmed</span>
              ) : entry?.dateOfferDeclinedSeller ? (
                <span className="badge bg-danger px-2 py-1">Declined</span>
              ) : (
                <span className="badge bg-warning px-2 py-1">
                  {entry?.dateOfferDeclinedRequestor ? "-" : "Pending"}
                </span>
              )}
            </div>
          </div>
          {entry?.offerDeclinedMessage && (
            <div className="mt-3">
              <b>Reason: </b>
              <p>{entry?.offerDeclinedMessage}</p>
            </div>
          )}
        </li>
      ) : (
        <>
          {entry?.statusIndex == statusIndex - 1 &&
            entry?.qcLaborID == myUser?.company?.id && (
              <QCOfferFileDrop entry={entry} reload={reload} />
            )}

          {entry?.statusIndex == statusIndex && isUserSeller() && (
            <li
              className={
                "event-list " +
                (entry?.statusIndex == statusIndex ? "active" : "")
              }
            >
              <a
                href={entry?.offerFileURL}
                target="_blank"
                rel="noreferrer"
                className="text-dark"
              >
                <div
                  className={
                    "event-timeline-dot " +
                    (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
                  }
                >
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      {moment(entry?.dateOfferUploaded).format("DD.MM.YYYY")}{" "}
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Offer provided by QC Lab</div>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <p>
                    Please wait. You will be notified as soon as the Buyer has
                    accepted the offer.
                  </p>
                </div>
              </a>
            </li>
          )}
        </>
      )}
    </>
  )
}

export default QCOfferListItem
