import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/Layout"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./App.css"
import MessageModal from "components/Common/MessageModal"
export const GlobalContext = React.createContext(null)

const App = props => {
  const [loading, setLoading] = useState(false)
  const [showLicenseModal, setShowLicenceModal] = useState(false)
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0)
  const [unreadQCRequestsCount, setUnreadQCRequestsCount] = useState(0)

  useEffect(() => {
    if (loading === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [loading])

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        unreadMessagesCount,
        setUnreadMessagesCount,
        unreadQCRequestsCount,
        setUnreadQCRequestsCount,
        setShowLicenceModal,
      }}
    >
      <React.Fragment>
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={true}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware checkUser={route.checkUser}>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Routes>
      </React.Fragment>
      <MessageModal
        show={showLicenseModal}
        icon="bx bx-no-entry"
        color={"danger"}
        displayMessage="This is a premium feature. Please upgrade your license!"
        onCloseClick={_ => setShowLicenceModal(false)}
      />
    </GlobalContext.Provider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
