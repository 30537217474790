import { GlobalContext } from "App"
import { useContext } from "react"
import { useLocation } from "react-router-dom"

export const useLoading = () => {
  const { setLoading } = useContext(GlobalContext)
  return setLoading
}

export const useUnreadMessagesCount = () => {
  const { unreadMessagesCount, setUnreadMessagesCount } =
    useContext(GlobalContext)
  return [unreadMessagesCount, setUnreadMessagesCount]
}

export const useUnreadQCRequestsCount = () => {
  const { unreadQCRequestsCount, setUnreadQCRequestsCount } =
    useContext(GlobalContext)
  return [unreadQCRequestsCount, setUnreadQCRequestsCount]
}

export const useShowLicenseModal = () => {
  const { setShowLicenceModal } = useContext(GlobalContext)
  return setShowLicenceModal
}

export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
