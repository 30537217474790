import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { useLoading } from "helpers/custom_hooks"
import { GetAllQCRequests, GetMyUser } from "helpers/backend_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import QCRequestsList from "./QCRequestsList"
import { IsQCModuleEnabled } from "helpers/misc_helpers"
import { Link } from "react-router-dom"

const QCRequests = props => {
  const setLoading = useLoading()
  const [entries, setEntries] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [myUser, setMyUser] = useState([])
  const [isQCEnabled, setIsQCEnabled] = useState()

  useEffect(() => {
    loadData()
    loadPermissions()
  }, [])

  const loadData = async _ => {
    setLoading(true)
    GetMyUser().then(result => setMyUser(result))
    const result = await GetAllQCRequests()
    setEntries(result)
    setDataLoaded(true)
    setLoading(false)
  }

  const loadPermissions = async _ => {
    const isQCEnabled = await IsQCModuleEnabled()
    setIsQCEnabled(isQCEnabled)
  }

  useEffect(() => {
    if (dataLoaded && isQCEnabled != undefined) setLoading(false)
  }, [dataLoaded, isQCEnabled])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isQCEnabled ? (
            <>
              <Breadcrumbs title="Requests" breadcrumbItem="QC" />
              <QCRequestsList entries={entries} myUser={myUser} />
            </>
          ) : (
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    4<i className="bx bx-buoy bx-spin text-primary display-3" />
                    3
                  </h1>
                  <h4 className="text-uppercase">
                    Sorry, you are not allowed to view this page
                  </h4>
                  <p className="text-primary">
                    This is a premium feature. To use this feature, please
                    upgrade your license!
                  </p>
                  <div className="mt-5 text-center">
                    <Link
                      className="btn btn-primary waves-effect waves-light "
                      to="/settings?tab=2"
                    >
                      Upgrade Account
                    </Link>
                    <br />
                    <Link className=" d-block mt-4" to="/dashboard">
                      <i className="bx bx-left-arrow-alt" />
                      Back to Dashboard
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QCRequests
