import React from "react"
import { Link } from "react-router-dom"

import { Col, TabPane } from "reactstrap"

const QCConfirmationTab = () => {
  return (
    <TabPane tabId={5}>
      <div className="row justify-content-center">
        <Col lg="6">
          <div className="text-center">
            <div className="mb-4">
              <i className="mdi mdi-check-circle-outline text-primary display-4" />
            </div>
            <div>
              <h5>Thanks for your request!</h5>
              <p className="text-muted">The QC Lab will contact you.</p>
              <Link to="/dashboard" className="btn btn-primary">
                Back to dashboard
              </Link>
            </div>
          </div>
        </Col>
      </div>
    </TabPane>
  )
}

export default QCConfirmationTab
