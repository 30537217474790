import { qcAddons } from "common/constants"
import React, { useEffect, useState } from "react"
import { Col, Row, Table, TabPane } from "reactstrap"

const QCAddonsTab = ({ nextTab, previousTab, request, setRequest }) => {
  const [selectedAddons, setSelectedAddons] = useState([])

  const handleNextTabClick = () => {
    setRequest({ ...request, addons: selectedAddons })
    if (request.id) nextTab(2)
    else nextTab(1)
  }

  const handleRowClick = id => {
    if (selectedAddons.includes(id)) {
      setSelectedAddons(selectedAddons.filter(addonId => addonId !== id))
    } else {
      setSelectedAddons([...selectedAddons, id])
    }
  }

  useEffect(() => {
    if (typeof request.addons === "string") {
      const parsedAddons = request.addons
        .split(",")
        .map(addon => parseInt(addon, 10))
      setSelectedAddons(parsedAddons)
    }
  }, [request])

  return (
    <TabPane tabId={2}>
      <Row>
        <Col lg="12">
          <div className="mt-3">
            <h5 className="mb-3">Add Ons :</h5>
            <p>Select any additional packages you may need:</p>

            <div className="table-responsive">
              <Table className="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th>Process</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {qcAddons.map(addon => (
                    <tr
                      key={addon.id}
                      onClick={() => handleRowClick(addon.id)}
                      style={{
                        cursor: "pointer",
                        backgroundColor: selectedAddons.includes(addon.id)
                          ? "#f0f0f0"
                          : "transparent",
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedAddons.includes(addon.id)}
                          onChange={() => handleRowClick(addon.id)}
                          onClick={e => e.stopPropagation()}
                        />
                      </td>
                      <th scope="row" style={{ width: "400px" }}>
                        {addon.process}
                      </th>
                      <td>{addon.quantity}</td>
                      <td>{addon.price}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end mt-4">
        <button
          type="button"
          className="btn btn-primary me-3"
          onClick={previousTab}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          type="button"
          disabled={!request?.qcTestID}
          onClick={handleNextTabClick}
        >
          Next
        </button>
      </div>
    </TabPane>
  )
}

export default QCAddonsTab
