import AccountDowngradeModal from "components/Common/AccountDowngradeModal"
import AccountUpgradeModal from "components/Common/AccountUpgradeModal"
import CardPricing from "components/Common/card-pricing"
import { IsQCLaborUser, IsQCModuleEnabled } from "helpers/misc_helpers"
import moment from "moment"
import React, { useState } from "react"
import { Col, Row } from "reactstrap"

const PricingSettingsTab = ({ user }) => {
  const [anual, setAnual] = useState(true)
  const [upgradeModal, setUpgradeModal] = useState(false)
  const [downgradeModal, setDowngradeModal] = useState(false)
  const qcModuleEnabled = IsQCModuleEnabled()

  const pricings = [
    {
      id: 1,
      title: "Free Package",
      description: "Pricing plan for free features",
      description2: "Access to our part search and watchlist.",
      price: anual ? "0" : "0",
      duration: anual ? "per year" : "per half year",
      link: _ => setDowngradeModal(true),
      btnText: user?.accountType == "Standard" ? "Current Plan" : "Downgrade",
      disabled: user?.accountType == "Standard",
      userType: "Free",
      features: [
        { title: "Limited part search", icon: "bx bx-check", color: "primary" },
        {
          title: "Upload your Excess material list and Watchlist",
          icon: "bx bx-check",
          color: "primary",
        },
        { title: "Single User Account", icon: "bx bx-check", color: "primary" },
        { title: "Buy / Sell parts", icon: "bx bx-x", color: "danger" },
      ],
    },
    {
      id: 2,
      title: "Basic Package",
      description: "Pricing plan for basic features",
      description2:
        "Unlimited access to all basic features and no user restrictions.",
      price: anual ? "500" : "250",
      duration: anual ? "per year" : "per half year",
      link: _ => setUpgradeModal(true),
      btnText: user?.accountType == "Premium" ? "Current Plan" : "Upgrade Plan",
      disabled: user?.accountType == "Premium",
      userType: "Standard",
      features: [
        {
          title: "Unlimited part search",
          icon: "bx bx-check",
          color: "primary",
        },
        { title: "Buy / Sell parts", icon: "bx bx-check", color: "primary" },
        { title: "Unlimited user", icon: "bx bx-check", color: "primary" },
        { title: "API interface", icon: "bx bx-x", color: "danger" },
      ],
    },
  ]

  const addons = [
    {
      id: 4,
      title: "Add-on QC-Module",
      description: "Standardized QC Test workflow",
      description2: "Ensures products meet set standards.",
      icon: "",
      price: anual ? "500" : "250",
      duration: anual ? "per year" : "per half year",
      disabled: qcModuleEnabled,
      btnText: qcModuleEnabled ? "active" : "Request QC-Module",
      link: _ =>
        window.open(
          "https://chipsconnect.com/chipsconnect-qc-registration",
          "_blank"
        ),
      features: [
        {
          title: "Standardized QC Test workflow",
          icon: "bx bx-check",
          color: "primary",
        },
        {
          title: "One-stop request management",
          icon: "bx bx-check",
          color: "primary",
        },
        {
          title: "Peer-to-peer contact Buyer-Seller-QC lab",
          icon: "bx bx-check",
          color: "primary",
        },
        { title: "Automatic Updates", icon: "bx bx-check", color: "primary" },
      ],
    },
    {
      id: 3,
      title: "Add-on API Package",
      description: "Pricing plan to get the API",
      description2: "No one-time costs for API implementation.",
      icon: "",
      price: anual ? "300" : "150",
      duration: anual ? "per year" : "per half year",
      disabled: false,
      btnText: "Request API",
      link: _ =>
        window.open(
          "https://chipsconnect.com/chipsconnect-api-registration/",
          "_blank"
        ),
      features: [
        { title: "API interface", icon: "bx bx-check", color: "primary" },
        { title: "API maintenance", icon: "bx bx-check", color: "primary" },
        { title: "Automatic updates", icon: "bx bx-check", color: "primary" },
        { title: "Basic package", icon: "bx bx-x", color: "danger" },
      ],
    },
  ]

  return (
    <div>
      {IsQCLaborUser() ? (
        <h3 className="m-3">Not available for QC-Labors</h3>
      ) : (
        <>
          <h5 className="mb-4">Pricing</h5>
          <Row>
            <Col lg="8" className="">
              <p>
                Here are our Packages which are currently available. <br />
                {user?.lastInvoice && (
                  <b className="me-2">
                    Next invoice:{" "}
                    {moment(user.lastInvoice)
                      .add(
                        user.billingPeriod == "half-yearly" ? 0.5 : 1,
                        "year"
                      )
                      .format("DD.MM.YYYY")}
                  </b>
                )}
                {user?.trialUntil && (
                  <b className="me-2">
                    Trial until:{" "}
                    {moment(user.company.trialUntil).format("DD.MM.YYYY")}
                  </b>
                )}
              </p>
            </Col>
          </Row>
          <Row className="justify-content-start mt-4">
            {pricings.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} />
            ))}
          </Row>

          <h3 className="mt-4">Add-Ons: </h3>

          <Row className="justify-content-start mt-4">
            {addons.map((addon, key) => (
              <CardPricing pricing={addon} key={"_addon_" + key} />
            ))}
          </Row>

          <AccountUpgradeModal
            onCancel={_ => setUpgradeModal(false)}
            onSuccess={_ => setUpgradeModal(false)}
            show={upgradeModal}
          />

          <AccountDowngradeModal
            onSuccess={_ => setDowngradeModal(false)}
            onCancel={_ => setUpgradeModal(false)}
            show={downgradeModal}
          />
        </>
      )}
    </div>
  )
}

export default PricingSettingsTab
