import React, { useState } from "react"
import { useLoading } from "helpers/custom_hooks"
import { UpdateQCRequest } from "helpers/backend_helper"
import { displayMessage } from "helpers/misc_helpers"
import moment from "moment"

const QCReleaseGoods = ({ entry, myUser, statusIndex, reload }) => {
  const setLoading = useLoading()
  const [error, setError] = useState()

  const handleSubmit = async accepted => {
    setLoading(true)
    try {
      entry.dateGoodsReleased = new Date()
      entry.statusIndex++

      await UpdateQCRequest(entry)
      reload()
    } catch (error) {
      error?.response?.status == 400
        ? displayMessage(error.response.data, setError)
        : displayMessage("An unexpected error happened!", setError)
      console.log(error)
    }
  }

  const isUserSeller = () => myUser?.company?.id == entry?.sellerCompanyID

  return (
    <>
      {entry?.dateGoodsReleased ? (
        <li
          className={
            "event-list " + (entry?.statusIndex == statusIndex ? "active" : "")
          }
        >
          <div
            className={
              "event-timeline-dot " +
              (entry?.statusIndex == statusIndex ? "bx-fade-right" : "")
            }
          >
            <i className="bx bx-right-arrow-circle font-size-18"></i>
          </div>
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-14">
                {moment(entry?.dateGoodsReleased).format("DD.MM.YYYY")}
                <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
              </h5>
            </div>
            <div className="flex-grow-1">
              <div>Goods released by seller</div>
            </div>
          </div>
        </li>
      ) : (
        entry?.statusIndex == statusIndex - 1 && (
          <>
            {isUserSeller() && (
              <>
                <p className="mx-3 ms-4">
                  {entry?.partRequestID ? (
                    <>
                      Please confirm your acceptance of the report so we can
                      proceed with the shipment. If you need to complete the
                      material request first, please do so and release the goods
                      afterward. The process must be finished in the material
                      request before the shipment to the Buyer can be initiated.
                      Please click on the button “Open Part-Request” to complete
                      the order workflow.
                    </>
                  ) : (
                    <>
                      Please release your goods so the QC lab can proceed with
                      the inspection and shipment.
                    </>
                  )}
                </p>
                <button
                  type="button"
                  onClick={_ => handleSubmit(true)}
                  className="btn btn-primary ms-3"
                >
                  Release goods
                </button>
              </>
            )}

            {!isUserSeller() && (
              <p className="ms-3">
                Please wait until the seller releases the goods.
              </p>
            )}
          </>
        )
      )}
    </>
  )
}

export default QCReleaseGoods
